import './HomeSecondPart.css';
import MissionIcon from '../imgs/mission.png';
import ProfessionalIcon from '../imgs/professional.png';
import SafeIcon from '../imgs/safe.png';
import ProjectIcon from '../imgs/project-management.png';

function HomeSecondPart() {
    /*Icon from website:
    <a href="https://www.flaticon.com/free-icons/mission" 
    title="mission icons">Mission icons created by deemakdaksina - Flaticon</a>*/
    const items = [
        {
            header:'Mission',
            context:"Brightentechs has a market leading team of specialists on hand, with over 20 skilled professionals across Sydney area and partnered over 100 contractor workforces. Now the business is rapidly evolving, especially in NBN new technology HFC and FTTC initiations.",
            image:MissionIcon,
            imgalt:'mission'
        },
        {
            header:'Professional',
            context:"Resourced qualified and experienced personnel.",
            image:ProfessionalIcon,
            imgalt:'professional'
        },
        {
            header:'Safe',
            context:"Provide sufficient training before onboarding and throughout the job, review SWMS regularly. Above industry average insurance.",
            image:SafeIcon,
            imgalt:'safe'
        },
        {
            header:'Project Management',
            context:"Efficient Project Management Team to ensure every project delivered on time, on quality and on budget.",
            image:ProjectIcon,
            imgalt:'project management'
        },
    ];
    const boxs = items.map((item)=>{
        return (
                <div className='homesecondpart-box'>
                    <h3>{item.header}</h3>
                    <p>{item.context}</p>
                    <img src={item.image} alt={item.imgalt}/>
                </div>
        )
    })
    return (
        <div className='homesecondpart-style'>
            <div className='homesecondpart-content'>
                {boxs}
            </div>
        </div>
    )
}

export default HomeSecondPart;