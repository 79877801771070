import './Banner.css';
import { NavLink } from 'reactstrap';

function Banner(props) {
    return(
        <div className='banner-div'>
            <div className='banner-div-center'>
                <h1>{props.name}</h1>
                <div className='banner-breadcrumb'>
                    <NavLink href="/">Home</NavLink>
                    <NavLink href="#">/</NavLink>
                    <NavLink href={props.link}>{props.name}</NavLink>
                </div>
            </div>
        </div>
    )
}

export default Banner;