import React from "react";
import './ApproachPart.css';

function ApproachPart(props) {
    const style = "approach-container " + props.class;

    return (
        <div className={style}>
            <div className="approach-heading" data-aos= "fade-down">{props.heading}</div>
            <div className="approach-content" data-aos= "fade-down">{props.content}</div>
        </div>
    )
}
export default ApproachPart;