import Contactus from "../components/Contactus";
import HomeFirstPart from "../components/HomeFirstPart";
import HomeFourthPart from "../components/HomeFourthPart";
import HomeSecondPart from "../components/HomeSecondPart";
import HomeThirdPart from "../components/HomeThirdPart";

function Home(){
  return (
    <div>
      <HomeFirstPart/>
      <HomeSecondPart/>
      <HomeThirdPart/>
      <HomeFourthPart/>
      <Contactus />
    </div>
  )
}

export default Home;