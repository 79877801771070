import Banner from "../components/Banner";
import Contactus from "../components/Contactus";
import HistoryTimeline from "../components/HistoryTimeline";


function History() {
    return (
        <div>
            <Banner name="History" link="/history"/>
            {/* timeline bar*/}
            <HistoryTimeline />
            <Contactus />
        </div>
    )
}

export default History;