import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
  import './Navigation.css';
  import Logo from '../imgs/Logo.png';

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
        <Navbar color="white" light expand="md" className='navigation-style' fixed='top'>
          <NavbarBrand href="/" className='navigation-logo'><img src={Logo} alt="logo"/></NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/">HOME</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  WHO WE ARE
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>
                    <NavLink href="/aboutus">ABOUT US</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/history">OUR HISTORY</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink href="/what">WHAT WE DO</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/where">WHERE WE WORK</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/approach">OUR APPROACH</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/career">CAREER</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact">CONTACT US</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
    );
  }
}