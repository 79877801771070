import Banner from "../components/Banner";
import CareerFirstPart from "../components/CareerFirstPart";
import Contactus from "../components/Contactus";

function Career(){
    return (
        <div>
            <Banner name="Career" link="/career"/>
            <CareerFirstPart />
            <Contactus />
        </div>
    )
 }

 export default Career;