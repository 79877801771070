import ServiceStream from '../imgs/servicestream_icon.png';
import Tandem from '../imgs/tandem_icon.png';
import Lendlease from '../imgs/lendlease_icon.png';
import Downer from '../imgs/downer_icon.png';
import Nbn from '../imgs/nbn_icon.png';
import Rfi from '../imgs/rfi_icon.png';
import Decon from '../imgs/decon_icon.webp';
import QCcomms from '../imgs/qc_icon.png';
import Skycomms from '../imgs/skycomms_icon.png';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './HistoryTimeline.css';

function HistoryTimeline() {
    /* timeline:
    https://github.com/stephane-monnot/react-vertical-timeline */
    return (
        <div>
            {/*<div className="history-timeline-first-part-div">
                <p>
                    Brightentechs is a communications engineering services company founded by experienced communication professionals in 2015.

                </p>
    </div>*/}
        <VerticalTimeline className='vertical-timeline-div' lineColor={'rgb(66, 66, 168)'}>
            {/*<VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                date="2011 - present"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            >
                <h3 className="vertical-timeline-element-title">Creative Director</h3>
                <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4>
                <p>
                Creative Direction, User Experience, Visual Design, Project Management, Team Leading
                </p>
    </VerticalTimelineElement>*/}
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="2017"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            >
                <h3 className="vertical-timeline-element-title">2017</h3>
                <h4 className="vertical-timeline-element-subtitle">Partner with</h4>
                <div className='vertical-timeline-element-image'>
                    <img src={ServiceStream} alt="servicestream"/>
                    <img src={Tandem} alt="tandem"/>
                    <img src={Lendlease} alt="lendlease"/>
                    <img src={Downer} alt="downer"/>
                </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="2018"
                iconStyle={{ background: 'rgb(66, 66, 168)', color: '#fff' }}
            >
                <h3 className="vertical-timeline-element-title">2018</h3>
                <h4 className="vertical-timeline-element-subtitle">Rich NBN MDU, In Building Coverage Equipment Installation experiences</h4>
                <div className='vertical-timeline-element-image'>
                    <img src={Nbn} alt="nbn"/>
                </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="2019"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            >
                <h3 className="vertical-timeline-element-title">2019</h3>
                <h4 className="vertical-timeline-element-subtitle">Aiming at Commercial Market</h4>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2020"
                iconStyle={{ background: 'rgb(66, 66, 168)', color: '#fff' }}
            >
                <h3 className="vertical-timeline-element-title">2020</h3>
                <h4 className="vertical-timeline-element-subtitle">Start to deploy wireless projects</h4>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2021"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            >
                <h3 className="vertical-timeline-element-title">2021</h3>
                <h4 className="vertical-timeline-element-subtitle">Partner with</h4>
                <div className='vertical-timeline-element-image'>
                    <img src={Rfi} alt="rfi"/>
                    <img src={Decon} alt="decon"/>
                    <img src={QCcomms} alt="qccomms"/>
                    <img src={Skycomms} alt="skycomms"/>
                </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2022"
                iconStyle={{ background: 'rgb(66, 66, 168)', color: '#fff' }}
            >
                <h3 className="vertical-timeline-element-title">2022</h3>
                <h4 className="vertical-timeline-element-subtitle">Accredited ISO9000/45001/14001 Certificates</h4>
            </VerticalTimelineElement>
            </VerticalTimeline>
            </div>
    )
}

export default HistoryTimeline;