import { Button, NavLink } from 'reactstrap';
import './Contactus.css';

function Contactus() {
    return (
        <div className='contactus-div'>
            <div className='contactus-subdiv'>
                <Button
                    color='primary'
                    className='contactus-button'
                >
                    <NavLink href="/contact">Contact Us Now</NavLink>
                </Button>
            </div>
        </div>
    )
}

export default Contactus;