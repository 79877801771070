import './ContactFirstPart.css';

function ContactFirstPart() {
    return (
        <div className ='contact-detail-address'>
            <div className = 'contact-detail-map'>
                <iframe title='contact-detail-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.0007543301053!2d151.0296714150587!3d-33.838091180664726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a34e8fd7914d%3A0x76036a8b8c5c8e0d!2s2%2F10%20Newton%20St%20S%2C%20Auburn%20NSW%202144!5e0!3m2!1sen!2sau!4v1635912981057!5m2!1sen!2sau"  width="100%" height="100%" frameBorder="0" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>    
            </div>
            <div className='contact-detail-div'>
                <div className='contact-detail-div-company'>Brightentechs</div>
                <div>Unit 2, 10 Newton Street South, Auburn, NSW, 2144</div>
                <div>+61 2 8068 0169</div>
                <div>info@brightentechs.com.au</div>
                <div>_____________________________</div>
                <div className='contact-detail-div-time'>9 AM to 5 PM Monday to Friday (public hoilday exclusive）</div>
            </div>         
        </div>
    )
}

export default ContactFirstPart;