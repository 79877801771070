import './HomeFourthPart.css';

function HomeFourthPart(){
    return (
        <div className='homefourthpart'>
            <div className='homefourthpart-text'>
                <h1>We Work Across NSW ACT VIC QLD WA</h1>
                <h4>Supporting growth across Australia's largest and most resource-rich state.</h4>
            </div>
        </div>
    )
}

export default HomeFourthPart;