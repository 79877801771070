import './WhatFirstPart.css';
import TelecomIcon from '../imgs/telecom_icon.png';
import ICTIcon from '../imgs/ICT_icon.png';
import SmartcityIcon from '../imgs/smartcity_icon.png';

function WhatFirstPart() {
    return (
        <div className='what-first-part-div'>
            <div className='what-first-part-heading'>OUR SERVICES</div>
            <div className='what-first-part-content'>Brightentechs has a strong focus on ICT Services – <strong>Telecommunications Engineering Service, ICT System Integration, Smart City</strong> – and mobilises our teams whenever and wherever customers need them – whether it is in remote, rural or metropolitan areas.</div>
            <div className='what-first-part-icon-group'>
                <div>
                    <img src={TelecomIcon} alt="telecom"/>
                    <p id='1'>Telecommunications Engineering Service</p>
                </div>
                <div>
                    <img src={ICTIcon} alt="telecom"/>
                    <p id='2'>ICT System Integration</p>
                </div>
                <div>
                    <img src={SmartcityIcon} alt="telecom"/>
                    <p id='3'>Smart City</p>
                </div>                
            </div>
        </div>
    )
}

export default WhatFirstPart;