import Banner from "../components/Banner";
import WhatFirstPart from "../components/WhatFirstPart";
import HomeThirdPart from '../components/HomeThirdPart';
import Contactus from '../components/Contactus';

function WhatWeDo(){
    return (
        <div>
            <Banner name="What We Do" link="/what"/>
            <WhatFirstPart />
            <HomeThirdPart />
            <Contactus />
        </div>
    )
 }

 export default WhatWeDo;