import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import LinkedinIcon from '../imgs/linkedinIcon.png';
import IOSIcon from '../imgs/ISO.png';

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-link-container">
        <div className="footer-link-items">
          <header className="footer-link-title">
            <h3>Menu</h3>
          </header>

          <ul className="footer-menu">
            <li className="footer-item">
              <Link to="/" className="footer-links">
                Home
              </Link>
            </li>
            <li className="footer-item">
              <Link to="/aboutus" className="footer-links">
                Who We Are
              </Link>
            </li>
            <li className="footer-item">
              <Link to="/what" className="footer-links">
                What We Do
              </Link>
            </li>
            <li className="footer-item">
              <Link to="/where" className="footer-links">
                Where We Work
              </Link>
            </li>
            <li className="footer-item">
              <Link to="/approach" className="footer-links">
                Our Approach
              </Link>
            </li>
            <li className="footer-item">
              <Link to="/career" className="footer-links">
                Career
              </Link>
            </li>
            <li className="footer-item">
              <Link to="/contact" className="footer-links">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div className="footer-link-items">
          <header className="footer-link-title">
            <h3>Contact</h3>
          </header>

          <div class="textwidget">
            <p>
              Brighten Technologies
              <br />
              Unit 2, 10 Newton Street South
              <br />
              Auburn, NSW 2144
            </p>
            <p>tel: 02 8068 0169</p>
            <p>info@brightentechs.com.au</p>
            <a
            href="https://www.linkedin.com/company/brighten-technologies/?originalSubdomain=au"
            target="_blank"
            rel="noreferrer"
          ><img src={LinkedinIcon} alt="linkedin" style={{width:'30px',height:'30px'}}/></a>
          </div>
        </div>

        <div className="footer-link-items">
          <header className="footer-link-title">
            <h3>Certificate</h3>
          </header>
          <img src={IOSIcon} alt="ISO Certification" width="100%"/>
        </div>
      </div>
    </div>
  );
}

export default Footer;
