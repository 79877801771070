import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import AboutUs from "./pages/AboutUs";
import History from "./pages/History";
import WhereWeWork from './pages/WhereWeWork.js';
import Approach from './pages/Approach.js';
import Career from './pages/Career.js';
import Contact from './pages/Contact.js';
import Footer from './components/Footer.js';
import Navigation from "./components/Navigation.js";
import WhatWeDo from "./pages/WhatWeDo.js";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navigation/>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route exact path="/aboutus" element={<AboutUs/>} />
            <Route exact path="/history" element={<History/>} />
            <Route exact path="/what" element={<WhatWeDo/>} />
            <Route exact path="/where" element={<WhereWeWork/>} />
            <Route exact path="/approach" element={<Approach/>} /> 
            <Route exact path="/career" element={<Career/>} />
            <Route exact path="/contact" element={<Contact/>} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
