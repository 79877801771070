import Banner from "../components/Banner";
import ContactFirstPart from "../components/ContactFirstPart";
import HomeFourthPart from '../components/HomeFourthPart';

function Contact(){
    return (
        <div>
            <Banner name="Contact us" link="/contact"/>
            <ContactFirstPart />
            <HomeFourthPart/>
        </div>
    )
 }

 export default Contact;