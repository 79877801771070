import Banner from "../components/Banner";
import Contactus from "../components/Contactus";
import HomeFourthPart from '../components/HomeFourthPart';

function WhereWeWork(){
    return (
        <div>
            <Banner name="Where We Wrok" link="/where"/>
            <HomeFourthPart />
            <Contactus />
        </div>
    )
 }

 export default WhereWeWork;