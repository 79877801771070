import './CareerFirstPart.css';
import Career1 from '../imgs/career1.png';
import Career2 from '../imgs/career2.png';
import Career3 from '../imgs/career3.png';
//import Career4 from '../imgs/career4.png';
import Career5 from '../imgs/career5.png';
import Career6 from '../imgs/career6.png';
//import Career7 from '../imgs/career7.png';
import Career8 from '../imgs/career8.png';
import Career9 from '../imgs/career9.png';
import Career10 from '../imgs/career10.png';
//import Career11 from '../imgs/career11.png';

function CareerFirstPart() {
    return (
        <div className='career-first-part-div'>
            <div className='career-first-part-heading'>JOIN OUR TEAM</div>
            <div className='career-first-part-content'>
                <strong>Brightentechs</strong> is a trusted partner of Australia's leading telecommunications and ICT network providers.
                We are young communications engineering services company founded by experienced communication professionals.
                Become Part of a Professional Team Working on Key Telecommunications Projects across Australia.            
            </div>
            <div className='career-first-part-content'>
                <strong>WE ARE HIRING...</strong>
            </div>
            <div className='career-first-part-icon-group'>
                <div>
                    <img src={Career1} alt="telecom"/>
                    <p id='1'>Project Managers</p>
                </div>
                <div>
                    <img src={Career2} alt="telecom"/>
                    <p id='2'>Civil Engineers</p>
                </div>
                <div>
                    <img src={Career3} alt="telecom"/>
                    <p id='3'>Project Engineers</p>
                </div>
                {/*<div>
                    <img src={Career4} alt="telecom"/>
                    <p id='3'>EME Design Engineers</p>
                </div> */}
                <div>
                    <img src={Career5} alt="telecom"/>
                    <p id='3'>Site Design Engineers</p>
                </div> 
                <div>
                    <img src={Career6} alt="telecom"/>
                    <p id='3'>Electricians</p>
                </div>
                {/*<div>
                    <img src={Career7} alt="telecom"/>
                    <p id='4'>Telecommunication Riggers</p>
    </div>   */}
                <div>
                    <img src={Career8} alt="telecom"/>
                    <p id='4'>Telecommunication Technicians</p>
                </div>   
                <div>
                    <img src={Career9} alt="telecom"/>
                    <p id='3'>Site Access Coordinator</p>
                </div>   
                <div>
                    <img src={Career10} alt="telecom"/>
                    <p id='3'>Document Controller</p>
                </div>   
                {/*<div>
                    <img src={Career11} alt="telecom"/>
                    <p id='3'>RF/IBC Designers</p>
                </div>  */}           
            </div>
            
        </div>
    )
}

export default CareerFirstPart;