import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import './HomeThirdPart.css';
import ProjectImg1 from '../imgs/project_card_1.png';
import ProjectImg2 from '../imgs/project_card_2.jpeg';
import ProjectImg3 from '../imgs/project_card_3.jpg';
import BackgroundIcon from '../imgs/logo_2.png';

const items = [
  {
    src: ProjectImg1,
    heading: "Smart warehouse/Logistic",
    caption: "Warehouse Automated Guided Vehicles",
    part: <ul>
            <li>Project Management</li>
            <li>Networking</li>
            <li>Infrastructure Design and Installation</li>
            <li>Geo tech detection</li>
            <li>System integration and test</li>
          </ul>,
    key: 1,
  },
  {
    src: ProjectImg2,
    heading: "Shopping center",
    caption:"NBN/Telstra Connections",
    part: <ul>
            <li>Example: QVB and Westfield</li>
          </ul>,
    key: 2,
  },
  {
    src: ProjectImg3,
    heading: "Public infrastructure",
    caption:"Telecommunication network upgrading Infrastructure Design & Installation Commissioning  & integration",
    part: <ul>
            <li>Telecommunication network upgrading</li>
            <li>Infrastructure Design and Installation</li>
            <li>Commissioning and Integration</li>
            <li>Example: Sydney domestic airport and Darling harbour theatre</li>
          </ul>,
    key:3,
  }
];

function HomeThirdPart(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div className='wwd-third-div'>
                <div className='wwd-third-left-div'>
                    <img src={item.src} alt="project"/>
                </div>
                <div className='wwd-third-right-div'>
                    <div className="about-text">
                        <h3>{item.heading}</h3>
                        <h5>{item.caption}</h5>
                        <div>{item.part}</div>
                    </div>
                </div>
        </div>
      </CarouselItem>
    );
  });

  return (
    <div className='homethirdpart-style'>
        <img className='homethirdpart-bg' src={BackgroundIcon} alt="background"/>
        <div className='wwd-third-container'>
            <div><h1>Our Projects</h1></div>
            <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            {...args}
            dark={true}
            >
                <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                />
                <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                />
            </Carousel>
        </div>
    </div>
  );
}

export default HomeThirdPart;