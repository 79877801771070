import React from "react";
import "./AboutFirstPart.css";
import AboutImg1 from '../imgs/about-1.png';
import AboutImg2 from '../imgs/about-2.png';

function AboutFirstPart(){
    return (
        <div className="about-part-div">
            <h3>About Us Overview</h3>
            <h2>SAFE WORK IS OUR KEY PRIORITY.</h2>
            <div className="about-first-part">
                <div className="about-first-part-left">
                    <img src={AboutImg1} alt="about-1"/>
                </div>
                <div className="about-first-part-right">
                    <p>
                        <strong>
                        Brightentechs is a trusted partner of Australia's leading
                        telecommunications and ICT network providers.
                        </strong>{" "}
                    </p>
                    <p>
                        We are young communications engineering services company founded
                        by experienced communication professionals in 2015. 
                    </p>
                    <p>
                        We began NBN
                        FTTB(Fiber To TheBuilding) projects since 2015, helping nearly
                        hundred of residents/commercials daily in connecting to NBN now.
                    </p>
                    <p>
                        We began NBN HFC(Hybrid Fiber Coax) since 2017 where partnership
                        set up over hundreds community broadband fundamental
                        constructions. 
                    </p>
                    <p>
                        Brightentechs has a market leading team of
                        specialists on hand, with over 20 skilled professionals across
                        Sydney area and partnered over 100 contractor workforces.
                    </p>
                </div>
            </div>
            <div className="about-second-part">
                <div className="about-second-part-left">
                    <p>
                        <strong>
                        Now the business is rapidly evolving, especially in NBN new
                        technology HFC and FTTC initiations.
                        </strong>
                    </p>

                    <p>
                        By designing bespoke solutions to meet customers objectives, we
                        mobilise teams whenever and wherever you need them most, whether
                        it is in remote, rural or metropolitan areas.
                    </p>
                </div>
                <div className="about-second-part-right">
                    <img src={AboutImg2} alt="about-2"/>
                </div>
            </div>
            
            
            
        </div>
    )
}

export default AboutFirstPart;