import AboutFirstPart from "../components/AboutFirstPart";
import Banner from "../components/Banner";
import Contactus from "../components/Contactus";

function AboutUs(){
    return (
        <div>
            <Banner name="About us" link="/aboutus"/>
            <AboutFirstPart />
            <Contactus />
        </div>
    )
 }

 export default AboutUs;