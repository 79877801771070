import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import HomePic1 from '../imgs/homepic1.png';
import HomePic2 from '../imgs/homepic2.png';
import HomePic3 from '../imgs/homepic3.png';
import HomePic4 from '../imgs/homepic4.png';
import './HomeFirstPart.css';

const items = [
  {
    src: HomePic1,
    altText: 'Slide 1',
    caption: "A trusted partner of Australia's leading telecommunications and ICT network providers",
    header: 'Brighten Technology',
    key: 1,
  },
  {
    src: HomePic2,
    altText: 'Slide 2',
    caption: '',
    header:'Telecommunications Engineering Service',
    key: 2,
  },
  {
    src: HomePic3,
    altText: 'Slide 3',
    caption: '',
    header:'ICT System Integration',
    key: 3,
  },
  {
    src: HomePic4,
    altText: 'Slide 4',
    caption: '',
    header:'Smart City',
    key: 4,
  },
];

function HomeFirstPart(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div className='home-first-part-items'>
          <img src={item.src} alt={item.altText} />
          <div className='home-first-part-items-text'>
            <h3>{item.header}</h3>
            <p>{item.caption}</p>
          </div>
        </div>
        
        {/*<CarouselCaption className='carselcaption-style'
        captionText={item.caption}
        captionHeader={item.header}
    />    */}    
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...args}
      fade={true}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}

export default HomeFirstPart;