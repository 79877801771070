import Banner from "../components/Banner";
import ApproachPart from "../components/ApproachPart";
import Contactus from '../components/Contactus';
import React, { useEffect, useState } from 'react';

function Approach(){
    const [ count, setCount ] = useState(0);
    let approach = <ApproachPart heading="Safety & Health" 
                                        content="At BTK, we put the safety and health of our people, 
                                                our clients and our communities above all else. 
                                                It is a fundamental guiding principle for everything we do."
                                        class="approach-container-1"/>;
    useEffect(()=>{
        const timer = setInterval(()=>{
                setCount((count)=>(count+1)%4)
        },4500);
        return ()=>{
                clearInterval(timer)
        }
    },[])

    if (count === 0){
        approach =<ApproachPart heading="Safety & Health" 
        content="At BTK, we put the safety and health of our people, 
                our clients and our communities above all else. 
                It is a fundamental guiding principle for everything we do."
        class="approach-container-1"/>
        }
        if (count === 1){
        approach =<ApproachPart heading="Community" 
        content="Community is at the heart of who we are. 
                Creating a strong community tie is fundamental to our success in the long run, 
                as well as to our clients.                      "
        class="approach-container-2"/>
        }
        if (count === 2){
        approach =<ApproachPart heading="Diversity" 
        content="We support our commitment to improving diversity in background, 
                age, gender, skills, experience and thought throughout our operations, 
                committed to progressing workplace equality across our business."
        class="approach-container-3"/>
        }
        if (count === 3){
        approach =<ApproachPart heading="Sustainability" 
        content="We believe we have an opportunity to make a positive impact 
                on the people and the world around us."
        class="approach-container-4"/> 
        }
    
    return (
        <div>
                {/*<span>{count}</span>*/}
            <Banner name="Our Approach" link="/approach"/>
            <div>
                {approach}
            </div>
            <Contactus />
        </div>
    )
 }

 export default Approach;